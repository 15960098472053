<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col lg="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">รายการโปรโมชั่น</h1>
        </b-col>
        <b-col lg="8" class="text-right">
          <div class="d-block d-sm-flex">
            <div class="d-flex align-items-center justify-content-end w-100">
              <b-input-group class="panel-input-serach">
                <b-form-input
                  class="input-serach"
                  placeholder="ค้นหาชื่อโปรโมชั่น"
                  v-model="filter.search"
                  @keyup="handleSearch"
                ></b-form-input>
                <b-input-group-prepend @click="btnSearch">
                  <span class="icon-input m-auto pr-2">
                    <font-awesome-icon icon="search" title="View" />
                  </span>
                </b-input-group-prepend>
              </b-input-group>
              <!-- <b-button
                v-b-toggle.sidebar-1
                class="ml-2 btn-filter btn-filter-faq"
              >
                <font-awesome-icon
                  icon="filter"
                  title="filter-btn"
                  class="text-white mr-0 mr-sm-1"
                />
                <span class="d-none d-sm-inline one-line">ตัวกรอง</span>
              </b-button> -->
              <router-link :to="'/promotion/details/0'">
                <b-button class="ml-2 btn-main">
                  <span class="d-none d-sm-inline one-line"
                    >สร้างโปรโมชั่น</span
                  >
                </b-button>
              </router-link>
              <b-dropdown class="ml-1 p-1 lang-dd border-0" right>
                <b-dropdown-item-button @click="createPromotion(1)"
                  >Product Promotion</b-dropdown-item-button
                >
                <b-dropdown-item-button @click="createPromotion(8)"
                  >Header Discount</b-dropdown-item-button
                >
              </b-dropdown>
            </div>
          </div>
        </b-col>
      </CRow>
      <!-- <b-sidebar
        id="sidebar-1"
        title="ค้นหาแบบละเอียด"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button type="button" class="btn btn-link px-0">
              ล้างค่า
            </button>
          </div>

          <div class="text-center mt-4">
            <button type="button" class="btn btn-purple button">
              ค้นหา
            </button>
          </div>
        </div>
      </b-sidebar> -->
      <b-row class="no-gutters px-3 px-sm-0 mt-3 overflow-auto">
        <b-col>
          <b-button-group class="btn-group-status">
            <b-button
              v-for="(item, index) in statusList"
              :key="index"
              @click="getDataByOrderStatus(item.name, item.id)"
              :class="{ menuactive: isActive(item.name) }"
              >{{ item.name }} ({{ item.count }})</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
      <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(sortOrder)="data">
                <span>{{ data.item.sortOrder }}</span>
              </template>
              <template v-slot:cell(name)="data">
                <span>{{ data.item.name }}</span>
              </template>
              <template v-slot:cell(promotionType)="data">
                <span>{{ data.item.promotionType }}</span>
              </template>
              <template v-slot:cell(tiers)="data">
                <span class="mt-1">{{ data.item.tiers }}</span>
              </template>
              <template v-slot:cell(startDateTime)="data">
                <span>
                  {{
                    new Date(data.item.startDateTime) | moment($formatDateTime)
                  }}
                </span>
              </template>
              <template v-slot:cell(endDateTime)="data">
                <span>
                  {{
                    new Date(data.item.endDateTime) | moment($formatDateTime)
                  }}
                </span>
              </template>
              <template v-slot:cell(status)="data">
                <div
                  v-if="data.item.status == 'ดำเนินการอยู่'"
                  class="text-success"
                >
                  ดำเนินการอยู่
                </div>
                <div
                  v-else-if="data.item.status == 'รอดำเนินการ'"
                  class="text-warning"
                >
                  รอดำเนินการ
                </div>
                <div v-else class="text-danger">สิ้นสุดแล้ว</div>
              </template>
              <template v-slot:cell(updatedTime)="data">
                <span>
                  {{
                    new Date(data.item.updatedTime) | moment($formatDateTime)
                  }}
                </span>
              </template>
              <template v-slot:cell(action)="data">
                <div class="text-center">
                  <router-link :to="'/promotion/details/' + data.item.id">
                    <b-button variant="link" class="text-dark px-1 py-0">
                      แก้ไข
                    </b-button>
                  </router-link>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";

export default {
  components: {},
  data() {
    return {
      filter: {
        PageNo: 1,
        PerPage: 10,
        search: "",
        startDate: "",
        endDate: "",
        status: [0],
      },
      fields: [
        {
          key: "sortOrder",
          label: "ลำดับ",
          class: "w-50px",
        },
        {
          key: "name",
          label: "ชื่อโปรโมชั่น",
          class: "w-100px",
        },
        {
          key: "promotionType",
          label: "ประเภทโปรโมชั่น",
          class: "w-100px",
        },
        {
          key: "tiers",
          label: "Tier",
          class: "w-200px",
        },
        {
          key: "startDateTime",
          label: "วันที่เริ่ม",
          class: "w-100px",
        },
        {
          key: "endDateTime",
          label: "วันที่สิ้นสุด",
          class: "w-100px",
        },
        {
          key: "status",
          label: "สถานะ",
          class: "w-100px",
        },
        {
          key: "updatedTime",
          label: "อัพเดทล่าสุด",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      timer: null,
      statusList: [],
      activeItem: "",
    };
  },
  created: async function () {
    await this.getData();
    this.activeItem = "ทั้งหมด";
  },
  methods: {
    formatDate(value) {
      if (value) {
        let startDateYear = parseInt(value.slice(0, 4)) - 543;
        value = `${startDateYear}${value.substr(4)}`;
        return this.$moment(value).format(this.$formatDateTime);
      }
    },
    createPromotion(val) {
      this.$store.commit("setPromotionType", val);
      this.$router.push({
        path: `/promotion/details/0`,
      });
    },
    async getData() {
      this.$isLoading = false;
      let list = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/promotion/list`,
        null,
        this.$headers,
        this.filter
      );

      if (list.result === 1) {
        this.items = list.detail.datas;
        this.rows = list.detail.count;
      }

      let status = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/promotion/CountWithStatus`,
        null,
        this.$headers,
        null
      );
      if (status.result === 1) {
        this.statusList = status.detail;
      }

      this.$isLoading = true;
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        this.getData();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getData();
    },
    pagination(Page) {
      window.scrollTo(0, 0);
      this.filter.PageNo = Page;
      this.getData();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getData();
    },
    getDataByOrderStatus(status, id) {
      this.activeItem = status;
      this.filter.status = [];
      if (id != 0) this.filter.status.push(id);
      this.filter.PageNo = 1;
      this.getData();
    },
    isActive: function (menuItem) {
      return this.activeItem == menuItem;
    },
  },
};
</script>

<style scoped>
.menuactive {
  color: #ffb300 !important;
}

::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}

::v-deep .dropdown-item {
  padding: 0.75rem 1.25rem !important;
}

::v-deep .lang-dd button:focus {
  background-color: #fff !important;
  color: #4f5d73;
}

::v-deep .lang-dd ul {
  width: 200px;
}
</style>
